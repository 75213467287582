@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
  scroll-padding: 6.5rem;
}

:root {
  --lighter-bg-div:rgb(54, 55, 58);
  --secondary-color: rgb(250, 176, 5);
  --main-bg-div: rgb(33, 34, 37);
  --main-text-color: rgb(200, 205, 207);
  --darker-bg-div: rgb(12, 13, 15);
  --third-color: #9800ff;
  --header-size:104px;
  --main-box-shadow:0px 8px 20px rgb(0 0 0 / 60%);
}

/* FDD540 */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  color: var(--main-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-thumb {
  background: rgb(94, 102, 115);
  height: 40px;
}

::-webkit-scrollbar-track {
  background: rgb(20, 21, 23);
}

body::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 7px;
  height: 1px;
}

a {
  text-decoration: none;
  color: var(--main-text-color);
}

.emptyDiv {
  height: 0.5rem;
  position: relative;
  z-index: 4;
  background-color: var(--main-bg-div);

}
.container {
  box-sizing: border-box;
  padding: 1rem 0;
  margin: auto;
  min-width: 0px;
  max-width: 1230px;
}

.container:nth-of-type(1) {
  padding: 0 0 1rem 0;
}
html {
  scroll-behavior: smooth !important;
  background-color: var(--main-bg-div) !important;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

main {
  scroll-margin-top: 112px;
  background-color: var(--main-bg-div);
}

.padding-top {
  padding-top: var(--header-size);
}

::selection {
  color: var(--secondary-color);
  background: var(--darker-bg-div);
}

.inPage {
  position: relative;
  z-index: 4;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: var(--main-bg-div);
}

.inPage_Padding {
  padding: 2rem 1.625rem;
}

.inPage_Container {
  min-height: calc(100vh - 9.875rem);
}
.app-inPage_Container{
  min-height: calc(100vh - 7.875rem);
}

.loading {
  font-style: italic;
  color: var(--secondary-color);
}

@media screen and (max-width: 991px) {
  :root{
    --header-size:64px
  }

  .padding-top {
    padding-top: var(--header-size);
  }

  html {
    scroll-padding-top: calc( var(--header-size) + 1rem) !important;
  }
}

@media screen and (min-width: 544px) {
  .inPage_Padding {
    padding: 2rem 1.625rem;
  }
}

@media screen and (min-width: 767px) {
  .inPage_Padding {
    padding: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .padding-left{
    padding-left: 260px;
  }
  .inPage_Padding {
    padding: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .navigation{
    display: none !important;
  }
}

.mantine-TransferList-transferListSearch:focus{
  border-bottom: 1px solid var(--secondary-color) !important;
}

.mantine-TransferList-transferListControl:hover {
  background: var(--lighter-bg-div);
}

.mantine-Checkbox-inner input:checked {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.mantine-Checkbox-inner svg {
  color: var(--darker-bg-div);
}

.mantine-ScrollArea-scrollbar:hover {
  background-color: transparent;
}

.mantine-ScrollArea-thumb {
  background: var(--lighter-bg-div) !important;
}

.banner {
  border-radius: 7px;
  box-shadow: var(--main-box-shadow);
  height: 34rem;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.banner_style {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  transition: all 1s;
  width: 100%;
}
.banner_style:hover {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}